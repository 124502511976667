import logo from "../logo.svg";

import Header from "./Header";
import Aboutme from "./Aboutme";
import Hero from "./Hero";
import Footer from "./Footer";
import Certificates from "./Certificates";
import Education from "./Education";
import Skills from "./Skills";
import Video from "./Video";
import ProjectHighlights from "./ProjectHighlights";

const HomePage = (props) => {
  return (
    <div>
      <Header header={props.header} />
      <Hero />
      <Aboutme />
      <Education />
      <Skills />
      {/* <Certificates /> */}
      <ProjectHighlights />
      {/* <Video /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
