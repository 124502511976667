import React from "react";
import ContactMe from "./ContactMe";
import ReactDOM from "react-dom";
import Header from "./Header";
import Footer from "./Footer";

const ContactMePage = (props) => {
  return (
    <>
      <Header header={props.header} />
      <ContactMe />
      <Footer />
    </>
  );
  //   const root = ReactDOM.createRoot(document.getElementById("root"));
  //   root.render(<ContactMe />);
};

export default ContactMePage;
