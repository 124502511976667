import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useSpring, useScroll, animated } from "react-spring";

const AboutPage = (props) => {
  const [state, toggle] = useState(true);
  const { x, y } = useSpring({
    from: { x: 0, y: 0 },
    to: { x: 1, y: 1 },
    // x: state ? 1 : 0,
    config: { duration: 500 },
  });

  const { scrollYProgress } = useScroll();
  return (
    <>
      <Header header={props.header} />

      <animated.div
        style={{ opacity: x, transform: y.interpolate((y) => `scale(${y})`) }}
      >
        <h2>Welcome to my Little World</h2>
      </animated.div>

      <animated.p
        style={{
          opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] }),
          transform: x
            .interpolate({
              range: [0, 1],
              output: [0.5, 1],
            })
            .interpolate((x) => `scale(${x})`),
        }}
        className="alignment"
      >
        My name is Maksat Ylyasov, and I am a Full Stack Developer. I specialize
        in developing web applications, both front-end and back-end, and enjoy
        working with technologies such as React, Angular, NodeJS, Django to
        create robust and scalable applications. <br />
        <br />I graduated with a Bachelor's degree in Electronics Engineering
        from Uludag University, and Master's degree in Electrical and
        Electronics Engineering from Marmara University. My technical expertise
        includes proficiency in several programming languages, such as
        JavaScript, Python, C/C++ as well as experience with databases, web
        servers, APIs, and frameworks such as React, Angular, Django, MongoDB,
        SQL. I am also well-versed in agile methodologies and have experience
        working in both Waterfall and Agile environments.
        <br />
        <br /> As a Full Stack Developer, I have a passion for creating seamless
        user experiences and solving complex technical problems. I love working
        in a collaborative environment and am always looking to learn new
        technologies and tools to enhance my skill set. In my free time, I enjoy
        reading about the latest trends in technology, attending meetups and
        conferences, and contributing to open-source projects. Thank you for
        visiting my page, and please feel free to contact me if you have any
        questions or if you are interested in working with me.
      </animated.p>
      <Footer />
    </>
  );
};

export default AboutPage;
