import React from "react";
import certificateIcon from "../assets/certificate-diploma.png";

const OtherCertificate = (props) => {
  let text = props.value;
  let text1 = text.split("/n");
  return (
    <a href={props.link}>
      <article>
        <img src={certificateIcon} alt="certificateIcon" />
        <p>{text}</p>
      </article>
    </a>
  );
};
export default OtherCertificate;
