import React, { useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";

import OtherCertificate from "./OtherCertificates";

import cloudImage from "../assets/cloud-certificate.jpg";
import ibmFullstack from "../assets/ibm-fullstack.jpg";
import fullstackAngular from "../assets/fullstack-angular.jpg";
import metaFrontend from "../assets/meta-front-end.jpg";
import metaBackend from "../assets/meta-back-end.jpg";

import certificateIcon from "../assets/certificate-diploma.png";

import { motion, useAnimation, useInView } from "framer-motion";

import { useEffect } from "react";
import {
  inViewAnimationLeft,
  inViewAnimationRight,
  inViewAnimationBottom,
  inViewAnimationTop,
} from "./animations/Animations";

import Animations from "./animations/Animations";

const otherCertificates = [
  "Version Control",
  "Build a Full Stack App using React and Express",
  "Build a Web App using React and Redux",
  "Principles of UX/UI Design",
  "Programming with JavaScript",
  "Introduction to Back-End Development",
  "HTML and CSS in depth",
  "Front-End Developer Capstone",
  "Introduction to Front-End Development",
  "Developing Applications with SQL, Databases, and Django",
  "Full Stack Cloud Development Capstone Project",
  "Front-End JavaScript Frameworks: Angular",
  "Advanced React",
  "Python Project for AI & Application Development",
  "Front-End Web UI Frameworks and Tools: Bootstrap 4",
  "Application Development using Microservices and Serverless",
  "React Basics",
  "Server-side Development with NodeJS, Express and MongoDB",
  "Python for Data Science, AI & Development",
  "Developing Cloud Apps with Node.js and React",
  "Developing Cloud Native Applications",
  "Introduction to Cloud Computing",
  "Introduction to Web Development with HTML, CSS, JavaScript",
  "Introduction to Containers w/ Docker, Kubernetes & OpenShift",
  "Introduction to Databases for Back-End Development",
  "The Full Stack",
  "Rest APIs",
  "Django Web Framework",
  "Back-End Developer Capstone",
  "Introduction to Back-End Development",
  "Programming in Python",
];
let certificateLink = {
  "Version Control":
    "https://www.coursera.org/account/accomplishments/certificate/SFCP6J2Z9NKD",
  "Build a Full Stack App using React and Express":
    "https://www.coursera.org/account/accomplishments/certificate/N2VZL63SHQZT",
  "Build a Web App using React and Redux":
    "https://www.coursera.org/account/accomplishments/certificate/4BB9KC2YFYSS",
  "Principles of UX/UI Design":
    "https://www.coursera.org/account/accomplishments/certificate/A78JPRCV593F",
  "Programming with JavaScript":
    "https://www.coursera.org/account/accomplishments/certificate/5JEE5LSL3Z8E",
  "Introduction to Back-End Development":
    "https://www.coursera.org/account/accomplishments/certificate/93DMMFTH39KG",
  "HTML and CSS in depth":
    "https://www.coursera.org/account/accomplishments/certificate/SAAHXRH2CVZQ",
  "Front-End Developer Capstone":
    "https://www.coursera.org/account/accomplishments/certificate/B29YGRKLUPXK",
  "Introduction to Front-End Development":
    "https://www.coursera.org/account/accomplishments/certificate/U5E5HSVK8SN6",
  "Developing Applications with SQL, Databases, and Django":
    "https://www.coursera.org/account/accomplishments/certificate/A5QQQY6XETLC",
  "Full Stack Cloud Development Capstone Project":
    "https://www.coursera.org/account/accomplishments/certificate/5PZCG8XZWNTP",
  "Front-End JavaScript Frameworks: Angular":
    "https://www.coursera.org/account/accomplishments/certificate/JRF447XW2G34",
  "Advanced React":
    "https://www.coursera.org/account/accomplishments/certificate/82GSD2V5P2P8",
  "Python Project for AI & Application Development":
    "https://www.coursera.org/account/accomplishments/certificate/LFCKWQGN32T3",
  "Front-End Web UI Frameworks and Tools: Bootstrap 4":
    "https://www.coursera.org/account/accomplishments/certificate/262DUFS8M2QK",
  "Application Development using Microservices and Serverless":
    "https://www.coursera.org/account/accomplishments/certificate/A8CG7UHX78JT",
  "React Basics":
    "https://www.coursera.org/account/accomplishments/certificate/U92GPW5NEJ44",
  "Server-side Development with NodeJS, Express and MongoDB":
    "https://www.coursera.org/account/accomplishments/certificate/77AV9A2JTW7T",
  "Python for Data Science, AI & Development":
    "https://www.coursera.org/account/accomplishments/certificate/UX2W3D34T9EV",
  "Developing Cloud Apps with Node.js and React":
    "https://www.coursera.org/account/accomplishments/certificate/L7TSXF4YM7K5",
  "Developing Cloud Native Applications":
    "https://www.coursera.org/account/accomplishments/certificate/N9JHQ26R8QEK",
  "Introduction to Cloud Computing":
    "https://www.coursera.org/account/accomplishments/certificate/RQT39WUMYA4S",
  "Introduction to Web Development with HTML, CSS, JavaScript":
    "https://www.coursera.org/account/accomplishments/certificate/TNLTTNB822YW",
  "Introduction to Containers w/ Docker, Kubernetes & OpenShift":
    "https://www.coursera.org/account/accomplishments/certificate/JBFE4XKPH7KC",
  "Introduction to Databases for Back-End Development":
    "https://www.coursera.org/account/accomplishments/certificate/JB52CRBQMHUV",
  "The Full Stack":
    "https://www.coursera.org/account/accomplishments/certificate/MVHBME6MB38D",
  "Rest APIs":
    "https://www.coursera.org/account/accomplishments/certificate/QBUGACCCE5DM",
  "Django Web Framework":
    "https://www.coursera.org/account/accomplishments/certificate/M3NU7DP6N7VP",
  "Back-End Developer Capstone":
    "https://www.coursera.org/account/accomplishments/certificate/6NXLBBKHFKD8",
  "Introduction to Back-End Development":
    "https://www.coursera.org/account/accomplishments/certificate/93DMMFTH39KG",
  "Programming in Python":
    "https://www.coursera.org/account/accomplishments/certificate/T683VBCCQL3M",
};

const CertificatePage = (props) => {
  let certificates = [];
  otherCertificates.forEach((element) => {
    certificates.push(
      <OtherCertificate value={element} link={certificateLink[element]} />
    );
  });
  // certificates = otherCertificates[0] + otherCertificates[1];
  // const test = (
  //   <OtherCertificate
  //     value={"Build a Full Stack App</br>using React and Express"}
  //   />
  // );

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const inViewAnimationLeft = {
    transform: isInView ? "none" : "translateX(-200px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
    // textAlign: "center",
  };
  const inViewMain = {
    transform: isInView ? "none" : "translateX(-200px)",
    opacity: isInView ? 1 : 0,
    // transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
    // textAlign: "center",
  };
  const inViewAnimationBottom = {
    transform: isInView ? "none" : "translateY(200px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s",
    // textAlign: "center",
  };
  const inViewAnimationTop = {
    transform: isInView ? "none" : "translateY(-200px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s",
    // textAlign: "center",
  };
  const inViewAnimationRight = {
    transform: isInView ? "none" : "translateX(100px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1.5s",
    // textAlign: "center",
  };
  const headerTextAnimation = {
    transform: isInView ? "none" : "scaleY(0)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1.5s",
    textAlign: "center",
  };

  return (
    <>
      <Header header={props.header} />
      <motion.main ref={ref} style={inViewMain}>
        <div>
          <h1 className="center-h1">Specialization Certificates</h1>
          <section className="certificates-page-specialization">
            <motion.article ref={ref} style={inViewAnimationLeft}>
              <h2>Meta Front-End Developer Specialization</h2>
              <div>
                <img src={metaFrontend} alt="metaFrontend certificate" />
              </div>
              <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/AMGG42W59S62">
                View Certificate
              </a>
            </motion.article>
            <motion.article ref={ref} style={inViewAnimationTop}>
              <h2>Full Stack Web Development with Angular Specialization</h2>
              <div>
                <img
                  src={fullstackAngular}
                  alt="fullstackAngular certificate"
                />
              </div>
              <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/J7RD9SASUUMV">
                View Certificate
              </a>
            </motion.article>
            <motion.article ref={ref} style={inViewAnimationRight}>
              <h2>IBM Full Stack Software Developer Specialization</h2>
              <div>
                <img src={ibmFullstack} alt="ibmFullstack certificate" />
              </div>
              <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/VULNLMWA6769">
                View Certificate
              </a>
            </motion.article>
            <motion.article ref={ref} style={inViewAnimationBottom}>
              <h2>Cloud Application Development Foundations Specialization</h2>
              <div>
                <img src={cloudImage} alt="cloud certificate" />
              </div>
              <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/GN9XNCN8SU8A">
                View Certificate
              </a>
            </motion.article>
            <motion.article ref={ref} style={inViewAnimationBottom}>
              <h2>Meta Back-End Developer Specialization</h2>
              <div>
                <img src={metaBackend} alt="meta back-end certificate" />
              </div>
              <a href="https://www.coursera.org/account/accomplishments/specialization/certificate/ZE6HP5RGQFJF">
                View Certificate
              </a>
            </motion.article>
          </section>
        </div>
        <motion.div>
          <h1 className="center-h1">Other Certificates</h1>
          <motion.section className="other-certificates">
            {certificates}
          </motion.section>
        </motion.div>
      </motion.main>
      <Footer />
    </>
  );
};
export default CertificatePage;
