import React, { useEffect } from "react";
import HeroImage from "../assets/bg-image-28.jpg";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = ["Full-Stack Developer", "Front-End Developer", "UX/UI Designer"];

const Hero = () => {
  const [index, setIndex] = React.useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <article className="hero">
      {/* <img src={HeroImage} width="auto"></img> */}
      <h1>Hi, I'm Maksat Ylyasov</h1>
      <div>
        {/* <h2 className="hero-header2">{professions[0]}</h2>
        <h2 className="hero-header2">Full-Stack Developer</h2>
        <h2 className="hero-header2">Full-Stack Developer</h2> */}

        <h2 className="hero-header2">
          <TextTransition springConfig={presets.wobbly}>
            {TEXTS[index % TEXTS.length]}
          </TextTransition>
        </h2>
      </div>
    </article>
  );
};

export default Hero;
