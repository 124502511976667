import React from "react";
import MyImage from "../assets/myimage.jpg";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const Aboutme = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
      // } else {
      //   control.start("hidden");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  return (
    <motion.main
      className="about-me alignment"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <section>
        <h1>About Me</h1>
        <p>
          Skilled with the latest cutting-edge web development tools and
          procedures. Proficient in an assortment of front-end and back-end
          technologies, including JavaScript, Angular, Node.js, Django, Cloud
          Computing, and React. Able to effectively self-manage during
          independent projects, as well as collaborate in a team setting.
        </p>
      </section>
      <section>
        <img className="about-me-image" src={MyImage} alt="face image" />
      </section>
    </motion.main>
  );
};

export default Aboutme;
