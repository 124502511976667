import React from "react";
import Logo from "../assets/logo-symbol-dark.png";

const Footer = () => {
  return (
    <footer>
      <section>
        <img src={Logo} alt="logo image" />
      </section>
      <section className="footer-wesite-links">
        <a href="">Home</a>
        <a href="">About</a>
        <a href="">Contact Me</a>
        <a href="">Portfolio</a>
        <a href="">Certificates</a>
      </section>
      <section className="footer-social-icons">
        <a className="fa-brands fa-github fa-2x" href=""></a>
        <a className="fa-brands fa-facebook fa-2x" href=""></a>
        <a className="fa-brands fa-linkedin fa-2x" href=""></a>
      </section>
      <p>© 2023, Maksat Ylyasov</p>
    </footer>
  );
};

export default Footer;
