import React from "react";
import universityLogo from "../assets/logo-university.png";
import universityLogo2 from "../assets/marmara-logo.png";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const Education = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
      // } else {
      //   control.start("hidden");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <motion.main
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <h1 className="center-h1">Education</h1>
      <section className="line-grids alignment">
        <article className="education ">
          <div>
            <img src={universityLogo} alt="university-logo" />
          </div>
          <div>
            <h3>Bachelor's Degree - Electronics Engineering</h3>
            <p>Uludag University, Bursa/Turkey</p>
          </div>
        </article>
      </section>
      <section className="line-grids alignment">
        <article className="education ">
          <div>
            <img src={universityLogo2} alt="university-logo" />
          </div>
          <div>
            <h3>Master's Degree - Electrical and Electronics Engineering</h3>
            <p>Marmara University, Istanbul/Turkey</p>
          </div>
        </article>
      </section>
    </motion.main>
  );
};
export default Education;
