import React, { useRef } from "react";
import ReactDOM from "react-dom";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect, useState } from "react";

import emailjs from "@emailjs/browser";

const ContactMe = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [emailValidation, SetEmailValidation] = useState(false);

  const form = useRef();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const [formValues, SetformValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const apiKey = "4477f990d69d4e71a055e28dafb6a3db";
  const apiURL =
    "https://emailvalidation.abstractapi.com/v1/?api_key=" + apiKey;
  const sendEmailValidationRequest = async (email) => {
    try {
      const response = await fetch(apiURL + "&email=" + email);
      const data = await response.json();
      console.log(data);
      const isValidSMTP = data.is_smtp_valid.value;

      if (isValidSMTP) {
        // use the email address in the mailto link
      } else {
        // show the user an error
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = (e) => {
    // alert(formValues.name);

    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs
      .sendForm(
        "service_nhfu0id",
        "template_skb7vg9",
        form.current,
        "n9vIe6sqiowjWncIe"
      )
      .then(
        (response) => {
          // show the user a success message
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          // show the user an error
          console.log("FAILED...", error);
        }
      );
    // sendEmailValidationRequest(e.target.email.value);

    // console.log(e.target.email.value);
    // console.log(formValues);
  };
  const handleInputChange = (e) => {
    // const { name, value } = e.target;
    // alert(e.target.value);
    SetformValues(e.target.value);
  };
  return (
    <motion.section
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="contactme-form"
    >
      <form
        ref={form}
        className="contactme"
        value={formValues}
        onSubmit={handleSubmit}
      >
        <h1>Contact Me</h1>

        <label htmlFor="name">Name </label>
        <input
          id="name"
          name="name"
          type="text"
          placeholder="Name"
          value={formValues.name}
          onChange={handleInputChange}
        ></input>

        <label htmlFor="email">Email </label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="email"
          value={formValues.email}
          onChange={handleInputChange}
        ></input>

        <label htmlFor="phone">Phone </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          //   pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          placeholder="number (optional)"
          value={formValues.phone}
          onChange={handleInputChange}
        ></input>
        <label htmlFor="message">Message </label>
        <textarea
          id="message"
          name="messages"
          cols="40"
          rows="10"
          placeholder="message"
          value={formValues.message}
          onChange={handleInputChange}
        ></textarea>

        {/* <button type="submit">Send</button> */}
        <input id="submit-button" type="submit" value="Send"></input>
      </form>
    </motion.section>
  );
};
export default ContactMe;
