import React, { useRef } from "react";

import { motion, useTime, useTransform, useInView } from "framer-motion";

// import portfolioImg1 from "../assets/portfolio-1.jpg";
import portfolioImg1 from "../assets/okayartist.png";
import portfolioImg2 from "../assets/portfolio-2.jpg";
import portfolioImg3 from "../assets/portfolio-3.jpg";
import portfolioImg4 from "../assets/portfolio-4.jpg";
import portfolioImg5 from "../assets/portfolio-5.jpg";
import portfolioImg6 from "../assets/portfolio-6.jpg";
import portfolioImg7 from "../assets/portfolio-7.jpg";
import portfolioImg8 from "../assets/portfolio-8.jpg";

const projects = [
  {
    header: "Okay Artist Fullstack Project",
    image: portfolioImg1,
    url: "https://okayartist.com",
  },
  {
    header: "Little Lemon Restaurant React Project",
    image: portfolioImg6,
    url: "https://little-lemon-react-app.vercel.app/",
  },

  {
    header: "Personal Portfolio React Project",
    image: portfolioImg3,
    url: "https://create-react-app-git-main-maksatylyasov.vercel.app/",
  },
];
const ProjectHighlights = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  //animation variable
  const inViewAnimationLeft = {
    transform: isInView ? "none" : "translateX(-200px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
    // textAlign: "center",
  };
  const inViewAnimationBottom = {
    transform: isInView ? "none" : "translateY(200px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s",
    // textAlign: "center",
  };
  const inViewAnimationTop = {
    transform: isInView ? "none" : "translateY(-200px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s",
    // textAlign: "center",
  };
  const inViewAnimationRight = {
    transform: isInView ? "none" : "translateX(100px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1.5s",
    // textAlign: "center",
  };
  const headerTextAnimation = {
    transform: isInView ? "none" : "scaleY(0)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1.5s",
    textAlign: "center",
  };

  return (
    <>
      <motion.h1 ref={ref} style={headerTextAnimation}>
        Latest Projects
      </motion.h1>
      <motion.section
        ref={ref}
        style={inViewAnimationLeft}
        className="projectHighlights"
      >
        <motion.article
          className="card"
          ref={ref}
          style={inViewAnimationTop}
          whileHover={{ opacity: 0.8 }}
          onClick={() => {
            window.open(projects[0].url);
          }}
        >
          <div>
            <h2>{projects[0].header}</h2>
            <img src={projects[0].image} alt={projects[0].header}></img>
          </div>
        </motion.article>
        <motion.article
          className="card"
          ref={ref}
          style={inViewAnimationBottom}
          whileHover={{ opacity: 0.8 }}
          onClick={() => {
            window.open(projects[1].url);
          }}
        >
          <div>
            <h2>{projects[1].header}</h2>
            <img src={projects[1].image} alt={projects[1].header}></img>
          </div>
        </motion.article>
        <motion.article
          className="card"
          ref={ref}
          style={inViewAnimationTop}
          whileHover={{ opacity: 0.8 }}
          onClick={() => {
            window.open(projects[2].url);
          }}
        >
          <div>
            <h2>{projects[2].header}</h2>
            <img src={projects[2].image} alt={projects[2].header}></img>
          </div>
        </motion.article>
      </motion.section>
    </>
  );
};

export default ProjectHighlights;
