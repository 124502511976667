import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Aboutme from "./components/Aboutme";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Certificates from "./components/Certificates";
import HomePage from "./components/HomePage";
import ReactDOM from "react-dom";
import ContactMePage from "./components/ContactMePage";
import ContactMe from "./components/ContactMe";

import RouteMap from "./Routes/Routes";
import { Routes, Route } from "react-router-dom";
import CertificatePage from "./components/CertificatesPage";
import AboutPage from "./components/AboutPage";
import PortfolioPage from "./components/PortfolioPage";

import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage header="Home" />}>
          {/* <Route path="contactme" element={<ContactMe />} /> */}
        </Route>
        <Route
          path="/contactme"
          element={<ContactMePage header="ContactMePage" />}
        />
        <Route
          path="/certificates"
          element={<CertificatePage header="CertificatePage" />}
        />
        <Route path="/about" element={<AboutPage header="AboutPage" />} />
        <Route
          path="/portfolio"
          element={<PortfolioPage header="PortfolioPage" />}
        />
      </Routes>
      {/* <Header />
      <Hero />
      <Aboutme />
      <Certificates />
      <Footer /> */}
      {/* <RouteMap /> */}
      {/* <HomePage />; */}
      <Analytics />
    </div>
  );
}
export default App;
