import React, { useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import portfolioImg1 from "../assets/portfolio-1.jpg";
import okayartistBanner from "../assets/okayartist.png";
import portfolioImg2 from "../assets/portfolio-2.jpg";
import portfolioImg3 from "../assets/portfolio-3.jpg";
import portfolioImg4 from "../assets/portfolio-4.jpg";
import portfolioImg5 from "../assets/portfolio-5.jpg";
import portfolioImg6 from "../assets/portfolio-6.jpg";
import portfolioImg7 from "../assets/portfolio-7.jpg";
import portfolioImg8 from "../assets/portfolio-8.jpg";
import portfolioImg9 from "../assets/portfolio-9.jpg";
import portfolioImg10 from "../assets/portfolio-10.jpg";
import portfolioImg11 from "../assets/portfolio-11.jpg";

import { useNavigate } from "react-router-dom";
import { motion, useTime, useTransform, useInView } from "framer-motion";

const PortfolioPage = (props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const url1 = "https://coruscating-biscotti-247e4e.netlify.app/home";
  const url2 = "https://frabjous-sfogliatella-505e89.netlify.app/home";
  const url3 = "https://create-react-app-git-main-maksatylyasov.vercel.app/";
  const url4 = "";
  let navigate = useNavigate();

  const projects = [
    {
      header: "Okay Artist Fullstack Project with React & Django",
      image: okayartistBanner,
      url: "https://okayartist.com",
    },
    {
      header: "Little Lemon Restaurant React Project",
      image: portfolioImg6,
      url: "https://little-lemon-react-app.vercel.app/",
    },
    {
      header: "Max Apparel Store Angular Project",
      image: portfolioImg1,
      url: "https://coruscating-biscotti-247e4e.netlify.app/home",
    },
    {
      header: "Restaurant Angular Project",
      image: portfolioImg2,
      url: "https://frabjous-sfogliatella-505e89.netlify.app/home",
    },
    {
      header: "Personal Portfolio React Project",
      image: portfolioImg3,
      url: "https://create-react-app-git-main-maksatylyasov.vercel.app/",
    },
    {
      header: "Personal Portfolio React Project2",
      image: portfolioImg3,
      url: "https://comforting-axolotl-39a287.netlify.app/",
    },
    {
      header: "CRUD App in React with Hooks",
      image: portfolioImg4,
      url: "https://crud-app-react-five.vercel.app/",
    },
    {
      header: "Simple Weather App With Vanilla JavaScript",
      image: portfolioImg5,
      url: "https://little-lemon-react-app-k7wx.vercel.app/",
    },

    {
      header: "Backgammon with Javascript",
      image: portfolioImg7,
      url: "https://backgammon-vanilla-js.vercel.app/",
    },
    {
      header: "Restful CRUD API with Node.js, Express and MongoDB",
      image: portfolioImg4,
      url: "https://github.com/maksatylyasov/Restful-CRUD-API-with-Node.js-Express-and-MongoDB",
    },
    {
      header: "Car Dealership Review Full-stack Django/Bootstrap",
      image: portfolioImg8,
      url: "https://github.com/maksatylyasov/Car-Dealership-Full-stack-Project",
    },
    {
      header: "Mountains Tour Website with HTML, CSS, JavaScript",
      image: portfolioImg9,
      url: "https://vercel.com/maksatylyasov/mountains-webpage-html-css-js/A43msuPuxaWErmBkrhaZwzkj3Zfj",
    },
    {
      header: "Little Lemon Restaurant Back-end with Django Project",
      image: portfolioImg10,
      url: "https://github.com/maksatylyasov/LittleLemon-Capstone-BackEnd-Project.git",
    },
    {
      header: "Little Lemon Restaurant Django API Project",
      image: portfolioImg11,
      url: "https://github.com/maksatylyasov/django-api-littlelemon.git",
    },
  ];

  //animation variable
  const inViewAnimationLeft = {
    transform: isInView ? "none" : "translateX(-200px)",
    opacity: isInView ? 1 : 0,
    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
  };

  var projectsHTML = [];

  projects.forEach((key) => {
    projectsHTML.push(
      <motion.article
        ref={ref}
        style={inViewAnimationLeft}
        onClick={() => {
          window.open(key.url);
        }}
      >
        <div>
          <h2>{key.header}</h2>
          <img src={key.image} alt={key.header}></img>
        </div>
      </motion.article>
    );
  });

  //animation variable
  const projectCard = {
    hidden: {
      transform: "translateX(-200px)",
      opacity: 0,
      transition: { duration: 1 },
    },
    visible: {
      transform: "translateX(0)",
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  //variants animations
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const Project = (props) => {
    return (
      <>
        <motion.article
          variants={projectCard}
          initial="hidden"
          animate="visible"
          onClick={() => {
            window.open(props.project.url);
          }}
        >
          <div>
            <h2>{props.project.header}</h2>
            <img src={props.project.image} alt={props.project.header}></img>
          </div>
        </motion.article>
      </>
    );
  };

  return (
    <>
      <Header header={props.header} />
      <section className="alignment">
        <article>
          <h1>My Latest Projects</h1>
          <p>
            These are some of my latest projects to share with you to
            demonstrate the ability of developing Web Apps
          </p>
        </article>
      </section>
      <section className="portfolio alignment">
        {/* <article
          onClick={() => {
            window.open(url1);
          }}
        >
          <div>
            <h2>Max Apparel Store Angular Project</h2>
            <img src={portfolioImg1} alt="portfolioImg1"></img>
          </div>
        </article>
        <article
          onClick={() => {
            window.open(url2);
          }}
        >
          <div>
            <h2>Restaurant Angular Project</h2>
            <img src={portfolioImg2} alt="portfolioImg1"></img>
          </div>
        </article>
        <article
          onClick={() => {
            window.open(url3);
          }}
        >
          <div>
            <h2>Personal Portfolio React Project</h2>
            <img src={portfolioImg3} alt="portfolioImg1"></img>
          </div>
        </article> */}
        {/* {projectsHTML} */}
        {projects.map((project, index) => (
          <motion.section
            key={index}
            variants={projectCard}
            initial="hidden"
            animate="visible"
          >
            <Project project={project}></Project>
          </motion.section>
        ))}
      </section>

      <Footer />
    </>
  );
};

export default PortfolioPage;
