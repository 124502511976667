import React from "react";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const skills = [
  "Javascript",
  "HTML",
  "CSS",
  "React",
  "Django",
  "Angular",
  "Python",
  "C/C++",
  "Node.js",
  "Express.js",
  "Bootstrap",
  "Adobe Illustrator",
  "Adobe Photoshop",
  "Cloud Computing",
  "Customer Service",
  "UI/UX Design",
  "MongoDB",
  "GitHub",
  "MySQL",
  "Amazon AWS",
  "Heroku",
  "Material UI",
];

const Skills = () => {
  let skillsElements = [];
  skills.forEach((element) => {
    skillsElements.push(<h3>{element}</h3>);
  });

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
      // } else {
      //   control.start("hidden");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 0.2 },
  };
  return (
    <motion.section
      className="alignment"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <h1 className="center-h1">Skills</h1>
      <section className="skills">{skillsElements}</section>
    </motion.section>
  );
};

export default Skills;
