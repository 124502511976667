import React, { useState } from "react";
import Logo from "../assets/logo-symbol-dark.png";
import { Outlet, Link, useNavigate } from "react-router-dom";
import hamburgerIcon from "../assets/hamburger.png";
const Header = (props) => {
  const [hamburger, setHamburger] = useState(false);

  const handleHamburgerClick = () => {
    setHamburger(!hamburger);
  };

  const headerClassname = hamburger
    ? "container-navbar-side"
    : "container-navbar";

  const showHamburger = hamburger ? "hamburger-icon" : "hamburger-icon";

  return (
    <>
      <div className={showHamburger}>
        <a onClick={handleHamburgerClick}>
          <img src={hamburgerIcon} alt="logo" />
        </a>
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
      </div>

      <header className={headerClassname}>
        <div>
          <img src={Logo} alt="logo" />
        </div>
        <ul>
          <li>
            <Link
              to="/"
              className={props.header === "Home" ? "header-navigator" : ""}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className={props.header === "AboutPage" ? "header-navigator" : ""}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contactme"
              className={
                props.header === "ContactMePage" ? "header-navigator" : ""
              }
            >
              Contact Me
            </Link>
          </li>
          <li>
            <Link
              to="/portfolio"
              className={
                props.header === "PortfolioPage" ? "header-navigator" : ""
              }
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="/certificates"
              className={
                props.header === "CertificatePage" ? "header-navigator" : ""
              }
            >
              Certificates
            </Link>
          </li>
        </ul>
      </header>

      <Outlet />
    </>
  );
};

export default Header;
